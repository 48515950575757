import React from 'react';
import { Typography, Container } from '@mui/material';

function Photos() {
    return (
        <Container maxWidth="sm">
            <Typography>
                <p>PHOTOS</p>
            </Typography>
        </Container>
    );
}

export default Photos;
